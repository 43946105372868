export const onClientEntry = () => {
  const loadingLayer = document.createElement("div");
  loadingLayer.innerHTML = `
  <div id="loading-layer">
    <style>
      #loading-layer{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: #000078;color: white;z-index: 10000;transition: opacity .4s ease;}
      #loading-layer span {position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);font-size: 100vh;}
      @media (max-aspect-ratio: 1/1) {font-size: 100vw;}}
      #loading-layer strong {display: block;animation: rotate 5s ease 1s infinite;}
      @keyframes rotate {0%, 100% {transform: rotate(0deg);} 50% {transform: rotate(360deg);}}
      .loaded #loading-layer {pointer-events: none;opacity: 0;}
    </style>
    <span aria-hidden="true">
      <strong>30</strong>
    </span>
  </div>`;
  document.body.appendChild(loadingLayer);
  window.addEventListener("load", (event) => {
    document.body.classList.add("loaded");
  });
};
